<template>
  <div class="product-table" :style="backCss">
    <div class="self-checkbox-all">
      <a-checkbox :checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange"></a-checkbox>
    </div>
    <a-spin :spinning="spinning">
      <!--  :scroll="{ x: 'max-content', y: 'calc(100vh - 323px)' }"  -->
      <vxe-table
        v-if="!spinning"
        style="border-top:1px solid rgb(232,232,232)"
        keep-source
        show-overflow
        ref="xTree"
        row-id="key"
        class="j-vxe-table"
        cell-class-name="j-vxe-table-cell-class"
        :row-config="{ isHover: true }"
        :data="tableData"
        :tree-config="treeConfig"
        @toggle-tree-expand="onToggleTreeExpand"
        @cell-click="onCellClick"
      >
        <vxe-column title="名称" width="340" :fixed="isDesktop() ? 'left' : false" tree-node show-header-overflow>
          <template v-slot="{ row }">
            <div :class="['tree-name', { 'department-name': row.isDepartment }]">
              <a-checkbox
                v-if="row.isProduct"
                class="tree-checkbox"
                :checked="row.checked"
                @change="onRowCheckBoxChange(row)"
              />

              <!-- <a-tooltip placement="top" :title="row.showName"> -->
              <span
                @click="e => onTitleClick(row, e)"
                :style="css"
                :class="['name', { 'product-name': row.isProduct }]"
              >
                <span
                  style="min-width:28px;display:inline-block"
                  v-if="row.isProduct && row.sequenceNo != undefined && row.sequenceNo !== '0'"
                >
                  {{
                    row.sequenceNo.length == 1
                      ? '00' + row.sequenceNo
                      : row.sequenceNo.length == 2
                      ? '0' + row.sequenceNo
                      : row.sequenceNo
                  }}-
                </span>
                <span
                  style="width:28px;display:inline-block"
                  v-else-if="row.isProduct && row.sequenceNo === '0'"
                ></span>
                {{ row.showName }}{{ row.adminUsers ? '-' : '' }}
                <template v-if="row.adminUsers">
                  <span v-for="(item, index) in row.adminUsers" :key="index"
                    >{{ (item && item.userName) || '' }} {{ index != row.adminUsers.length - 1 ? '|' : '' }}</span
                  >
                </template>
              </span>
              <!-- </a-tooltip> -->
            </div>
          </template>
        </vxe-column>
        <!-- <vxe-column title="序号" minWidth="50">
          <template v-slot="{ row }">
            <span v-if="row.isProduct && row.sequenceNo != undefined">{{ row.sequenceNo }}</span>
          </template>
        </vxe-column>-->
        <!-- <vxe-column title="统筹" minWidth="120">
          <template v-slot="{ row }">
            <div v-if="row.adminUser">
              <span>{{ row.adminUser.userName }}</span>
            </div>
            <div v-if="row.adminUsers">
              <span v-for="(item, index) in row.adminUsers" :key="index"
                >{{ (item && item.userName) || '' }} {{ index != row.adminUsers.length - 1 ? '|' : '' }}</span
              >
            </div>
          </template>
        </vxe-column>-->
        <vxe-column title="数量" width="50">
          <template v-slot="{ row }">
            <a-tooltip placement="right" class="table-tree-tool" color="#fff" v-if="row.isPlatform">
              <template slot="title">
                <span class="longin-tooltip">作品数量</span>
              </template>
              <span>{{ row.productTotal || 0 }}</span>
            </a-tooltip>
            <a-tooltip placement="right" class="table-tree-tool" color="#fff" v-else-if="row.isProduct">
              <template slot="title">
                <span class="longin-tooltip">章节数量</span>
              </template>
              <span>{{ row.chapterCount || 0 }}</span>
            </a-tooltip>
          </template>
        </vxe-column>
        <vxe-column title="单位" width="70">
          <template v-slot="{ row }">
            <span v-if="row.isPlatform">部</span>
            <span v-if="row.isProduct">话</span>
          </template>
        </vxe-column>
        <vxe-column title="未交稿预警" width="220">
          <template v-slot="{ row }">
            <div v-if="row.taskStatus">
              <span style="margin-right:3px">{{ row.taskStatus.chapterSort }}</span>
              {{ row.taskStatus.chapterSort && '|' }}
              <span style="margin-right:3px">{{
                row.taskStatus.currentStage && row.taskStatus.currentStage.stageName
              }}</span>
              {{ row.taskStatus.currentStage && '|' }}
              <span style="margin-right:3px">{{
                row.taskStatus.currentUser && row.taskStatus.currentUser.userName
              }}</span>
              {{ row.taskStatus.warningState && '|' }}
              <span>{{ row.taskStatus.warningState }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column title="拖稿数量">
          <template v-slot="{ row }">
            <div v-if="row.taskStatus">
              <!-- <span>完成数量：</span>
              <span style="display:inline-block;width:33px;">{{ row.taskStatus.finishedCount }}</span>-->
              <span style="color:red">{{ row.taskStatus.delayCount ? row.taskStatus.delayCount : '' }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column minWidth="200">
          <template v-slot="{ row }">
            <div @click.stop v-if="row.adminUsers && row.productTotal > 50">
              <a-pagination
                size="small"
                :showLessItems="true"
                :defaultPageSize="50"
                :default-current="1"
                :total="row.productTotal - 0"
                @change="
                  (page, pageSize) => {
                    treeTablePageChange(page, pageSize, row)
                  }
                "
              />
            </div>
          </template>
        </vxe-column>
        <!--         <vxe-column title="完成度" minWidth="460">
          <template v-slot="{ row }">
            <template v-if="row.isPlatform || row.isProduct">
              <span v-if="row.guideStatus">制作规范：已完成</span>
              <span v-else>
                <span>制作规范：</span>
                <span style="color: red">未完成</span>
              </span>
              <span class="mx-2">|</span>
              <span v-if="row.ruleStatus">阶段人员：已完成</span>
              <span v-else>
                <span>阶段人员：</span>
                <span style="color: red">未完成</span>
              </span>
              <span class="mx-2">|</span>
              <span v-if="row.termsStatus">术语表模板：已完成</span>
              <span v-else>
                <span>术语表模板：</span>
                <span style="color: red">未完成</span>
              </span>
            </template>
          </template>
        </vxe-column>-->

        <template v-slot:empty>
          <a-empty :image="simpleImage" />
        </template>
      </vxe-table>
      <div v-else style="width:100%;height:500px"></div>
    </a-spin>
    <div style="height:0;opacity:0;overflow:hidden">
      <a-table
        ref="table"
        id="out-table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :pagination="false"
        :dataSource="outTabelData"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 323px)' }"
      >
        <span slot="adminUsers" slot-scope="text, record">
          {{ (record.adminUser && record.adminUser.userName) || '' }}
        </span>
        <span slot="pagePattern" slot-scope="text, record">
          {{
            record.pagePattern == 'PIECE'
              ? '条漫'
              : record.pagePattern == 'PAGE'
              ? '页漫'
              : record.pagePattern == 'PIECE_PAGE'
              ? '条漫+页漫'
              : ''
          }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { getCurUserDepartTree, getProductList, deletePlatform, deleteProduct } from '@/api/productTree'
import { Empty } from 'ant-design-vue'
import OMS from '@/excel/excelOut.js'
import { checkPermission } from '@/utils/hasPermission'
import { mixinDevice } from '@/utils/mixin.js'
let treeCheckState = {}
let treeCheckedRow = {}

export default {
  name: 'product-table-tree',
  components: {},
  mixins: [mixinDevice],
  props: {
    showDeleteBtnFun: {
      type: Function,
      required: false,
      default: function() {}
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    productName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      spinning: false,
      // expandRowKeys: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      treeConfig: {
        // 全部展开
        expandAll: false,
        children: 'childrenList',
        lazy: true,
        indent: 30,
        reserve: true,
        hasChild: 'hasChild',
        expandRowKeys: [],
        loadMethod: this.loadChildrenMethod
      },
      checkAll: false,
      indeterminate: false,
      tableData: [],
      selectionRows: [],
      color: '',
      outTabelData: [],
      list: [],
      columns: [
        {
          title: '作品名称',
          dataIndex: 'productionName',
          width: 140
        },
        {
          title: '原文名',
          dataIndex: 'foreignName',
          width: 160
        },
        {
          title: '暂用名',
          dataIndex: 'temporaryName',
          width: 80
        },
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 80
        },
        {
          title: '平台',
          dataIndex: 'platformName',
          width: 100
        },
        {
          title: '统筹',
          dataIndex: 'adminUsers',
          scopedSlots: { customRender: 'adminUsers' },
          width: 100
        },
        {
          title: '形式',
          dataIndex: 'pagePattern',
          scopedSlots: { customRender: 'pagePattern' },
          width: 100
        }
      ],
      copyList: [],
      oldRowId: '',
      page: 1,
      nodeList: [],
      scrollList: [],
      moveNode: null
    }
  },
  watch: {
    checkAll(val) {
      this.showDeleteBtn()
    },
    indeterminate(val) {
      this.showDeleteBtn()
    },
    nodeList(val) {
      if (val.length) {
        if (
          document.getElementsByClassName('vxe-table--header')[0].getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document.getElementsByClassName('vxe-table--header')[0].getElementsByTagName('thead')[0].childNodes[1]
            )
        }
        document
          .getElementsByClassName('vxe-table--header')[0]
          .getElementsByTagName('thead')[0]
          .appendChild(val[val.length - 1])
      } else {
        if (
          document.getElementsByClassName('vxe-table--header')[0].getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document.getElementsByClassName('vxe-table--header')[0].getElementsByTagName('thead')[0].childNodes[1]
            )
        }
      }
    }
  },
  computed: {
    css() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    },
    backCss() {
      let color = this.color
      return {
        '--back-color': color
      }
    }
  },
  created() {
    this.getTreeData()
    this.$bus.$emit('getExpandRowKeys')
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 500)
  },
  methods: {
    copyNodeClick(e) {
      let node = document
        .getElementsByClassName('vxe-table--header-wrapper body--wrapper')[0]
        .getElementsByClassName('vxe-body--row')[0]
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      /*       console.log(e.target.dataset.icon)
      if (e.target.dataset.icon == 'right') {
        this.treeTablePageChange(
          this.page + 1,
          0,
          openArr[
            openArr.findIndex(i => {
              return i.key == node.attributes[0].value
            })
          ]
        )
      }
      return */
      if (
        openArr.findIndex(i => {
          return i.key == node.attributes[0].value
        }) > -1
      ) {
        this.tableData.forEach(item => {
          item.childrenList.forEach(i => {
            if (i.platformId == node.attributes[0].value.split('_')[1]) {
              this.$refs.xTree.clearTreeExpandLoaded(
                openArr[
                  openArr.findIndex(i => {
                    return i.key == node.attributes[0].value
                  })
                ]
              )
              this.$refs.xTree.updateData()
            }
          })
        })
      }
    },
    handleScroll(e) {
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      openArr = openArr.filter(item => item.isPlatform)
      let copyNode = null
      Array.from(document.getElementsByClassName('vxe-body--row')).forEach(item => {
        if (
          openArr.findIndex(i => {
            return i.key == item.attributes[0].value
          }) > -1
        ) {
          if (item.getClientRects()[0].top < 160) {
            copyNode = item.cloneNode(true)
            if (copyNode.getElementsByClassName('ant-pagination mini')[0]) {
              this.moveNode = null
              let parent = copyNode.getElementsByClassName('ant-pagination mini')[0].parentNode
              parent.removeChild(copyNode.getElementsByClassName('ant-pagination mini')[0])
              parent.appendChild(item.getElementsByClassName('ant-pagination mini')[0])
              this.moveNode = item
            }
            copyNode.style.backgroundColor = '#fff'
            /* copyNode.document.getElementsByClassName('col--last')[0].childNodes[0].childNodes[0].appendChild(item.getElementsByClassName('ant-pagination mini')[0]) */
            copyNode.addEventListener('click', this.copyNodeClick)
            if (
              this.nodeList.findIndex(n => {
                return n.attributes[0].value == copyNode.attributes[0].value
              }) == -1
            ) {
              this.scrollList.push(e.target.scrollTop)
              this.nodeList.push(copyNode)
            }
          }
        }
      })
      if (e.target.scrollTop < this.scrollList[this.scrollList.length - 1]) {
        this.scrollList.splice(this.scrollList.length - 1, 1)
        if (this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]) {
          let pageNode = this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]
          this.moveNode.childNodes[this.moveNode.childNodes.length - 1].childNodes[0].childNodes[0].appendChild(
            pageNode
          )
        }
        this.nodeList.splice(this.nodeList.length - 1, 1)
      }
    },
    checkPermission() {
      return checkPermission('platform:info:page:view')
    },
    exportExcel() {
      let that = this
      OMS.downLoadXlsx({
        dom: 'out-table', //获取表格dom，然后拿出数据，所以我需要知道解析哪一个表格
        name: '作品管理', //excel名字
        ColumnWdth: that.columns.map(item => {
          return item.width
        }) //每一列的宽度，需要直接指定，接受数字
      })
    },
    // 显示父组件中的删除按钮
    showDeleteBtn() {
      this.$emit('showDeleteBtnFun', Object.values(treeCheckState).includes(true))
    },
    refreshTable() {
      this.clearState()
      this.getTreeData()
    },
    clearState() {
      this.checkAll = false
      this.indeterminate = false
      treeCheckState = {}
      treeCheckedRow = {}
    },
    // 获取部门、平台树
    getTreeData(params = {}) {
      if (this.spinning) {
        return
      }
      this.spinning = true
      this.$bus.$on('postExpandRowKeys', keys => {
        if (keys) {
          this.treeConfig.expandRowKeys = [...keys]
        }
      })
      let nowParams = {
        productName: this.productName || '',
        platformIds: [],
        coordinatorIds: [],
        ...params
      }

      // 有搜索内容时，就关闭懒加载（因为已经有了作品列表数据
      /* if (nowParams.productName) {
        this.treeConfig.lazy = false
      } else {
        this.treeConfig.lazy = true
      } */

      this.clearState()
      getCurUserDepartTree(nowParams).then(res => {
        let list = res.data || []
        this.list = res.data || []
        this.copyList = JSON.parse(JSON.stringify(list))
        this.outTabelData = []
        this.copyList.forEach(item => {
          item.platformList?.forEach(child => {
            child.productionList?.forEach(production => {
              production.platformName = child.platformName
              this.outTabelData.push(production)
            })
          })
        })
        list.forEach((item, index) => {
          item.isDepartment = true
          item.index = index
          item.key = item.id
          item.checked = false
          item.showName = item.parentName || ''

          item.childrenList = item.platformList?.map((child, c_index) => {
            child.isPlatform = true
            child.checked = false
            child.index = c_index
            // 平台ID和作品ID可能会重复，影响表格渲染性能
            // 因此给平台重新定义key（平台相对较少
            child.key = `platform_${child.platformId}`
            child.hasChild = child.productTotal > 0
            child.showName = child.platformName || ''
            child.showCount = child.productTotal || 0
            child.childrenList = []

            // 只有搜索时，才会直接返回作品列表
            // if (child.productionList.length) {
            /* child.childrenList = this.setProductList(child.productionList) */
            // child.productionList = undefined
            // }

            return child
          })
          // 避免原有列表占用内存
          item.platformList = undefined
        })
        console.log(list)
        if (this.productName) {
          this.treeConfig.expandAll = true
        } else {
          this.treeConfig.expandAll = false
        }
        this.tableData = list
        this.spinning = false
        this.$nextTick(() => {
          document
            .getElementsByClassName('vxe-table--body-wrapper body--wrapper')[0]
            .addEventListener('scroll', this.handleScroll)
        })
      })
    },
    // 获取作品列表
    getProductData(params = {}) {
      return new Promise((resolve, reject) => {
        let nowParams = {
          productName: '',
          platformIds: [],
          coordinatorIds: [],
          productionPage: {
            size: -1,
            current: 1
          },
          ...params
        }

        /*         getProductList(nowParams)
                  .then((res) => {
                    let list = this.setProductList(res.data?.records || [])
        
                    resolve(list)
                  })
                  .catch((err) => {
                    reject(err)
                  }) */
      })
    },
    setProductList(list = []) {
      list.forEach((item, index) => {
        item.checked = false
        item.isProduct = true
        item.index = index
        item.key = item.id
        item.showCount = item.chapterCount || 0
        item.showName = item.productionName || item.foreignName

        treeCheckState[item.key] = false
      })
      setTimeout(
        () =>
          this.$nextTick(() => {
            console.dir(document.getElementsByClassName('vxe-table--body-wrapper body--wrapper')[0])
            document.getElementsByClassName('vxe-table--body-wrapper body--wrapper')[0].scrollTop += 1
          }),
        100
      )
      return list
    },
    // 加载子节点
    loadChildrenMethod({ row }) {
      if (this.productName) {
        return new Promise(resolve => {
          setTimeout(() => {
            let childrenList = []
            this.list.forEach(item => {
              item.childrenList?.forEach(i => {
                if (i.id == row.id) {
                  childrenList = i.productionList
                }
              })
            })
            resolve(this.setProductList(childrenList))
          }, 0)
        })
      }
      let params = {
        platformIds: [row.id],
        productionPage: {
          size: 50,
          current: this.page
        }
      }
      return getProductList(params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          console.log(res.data.records)
          res.data.records.forEach(item => {
            item.isProduct = true
          })
          return this.setProductList(res.data.records)
        }
      })
    },
    treeTablePageChange(page, pageSize, row) {
      let params = {
        platformIds: [row.id],
        productionPage: {
          size: 50,
          current: page
        }
      }
      this.page = page
      this.$refs.xTree.reloadTreeExpand(row)
      /*       getProductList(params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data.records.forEach(item => {
            item.isProduct = true
            item.key='row_'+item.id
          })
          row.childrenList=res.data.records
        }
      }) */
    },
    // 展开、收起
    onToggleTreeExpand({ expanded, row }) {
      console.log('点击展开按钮', expanded, row)
      if (expanded) {
        this.treeConfig.expandRowKeys.push(row.key)
      } else {
        this.treeConfig.expandRowKeys.splice(this.treeConfig.expandRowKeys.indexOf(row.key), 1)
      }
      this.$refs.xTree?.toggleTreeExpand(row)
    },
    // 自定义单行复选框
    onRowCheckBoxChange(row) {
      row.checked = !row.checked
      treeCheckState[row.key] = row.checked
      // 保存已经选中的行数据
      if (row.checked) {
        treeCheckedRow[row.key] = row
      } else {
        delete treeCheckedRow[row.key]
      }

      // 根据当前选中状态，处理全选还是选部分
      let checkAll = false
      let indeterminate = false
      checkAll = Object.values(treeCheckState).every(item => item === true)
      if (!checkAll) {
        indeterminate = Object.values(treeCheckState).includes(true)
      }
      let arr = []
      for (let key in treeCheckedRow) {
        arr.push(treeCheckedRow[key])
      }
      if (arr.length) {
        let arr2 = []
        this.outTabelData = []
        this.copyList.forEach(item => {
          item.platformList?.forEach(child => {
            child.productionList?.forEach(production => {
              production.platformName = child.platformName
              this.outTabelData.push(production)
            })
          })
        })
        arr.forEach(item => {
          this.outTabelData.forEach(child => {
            if (child.productionName == item.productionName) {
              arr2.push(child)
            }
          })
        })
        this.outTabelData = arr2
        this.$forceUpdate()
      } else {
        this.outTabelData = []
        this.copyList.forEach(item => {
          item.platformList?.forEach(child => {
            child.productionList?.forEach(production => {
              production.platformName = child.platformName
              this.outTabelData.push(production)
            })
          })
        })
        this.$forceUpdate()
      }
      this.checkAll = checkAll
      this.indeterminate = indeterminate
    },
    // 自定义表头全选框
    onCheckAllChange(e) {
      let checked = e.target.checked
      this.checkAll = checked
      this.indeterminate = false
      // 手动改变行复选框状态
      this.tableData.forEach(item => {
        item.childrenList?.forEach(child => {
          child.childrenList?.forEach(c_child => {
            c_child.checked = checked
            treeCheckState[c_child.key] = checked

            // 保存已经选中的作品行数据
            if (c_child.checked) treeCheckedRow[c_child.key] = c_child
            else delete treeCheckedRow[c_child.key]
          })
        })
      })
    },
    // 点击单元格
    onCellClick({ $event, row }) {
      // vxe-table组件内部的树、自定义复选框的className
      let nodeClass = [
        'col_2',
        'tree-name',
        'vxe-tree-cell',
        'ant-checkbox-input',
        'vxe-tree--node-btn',
        'vxe-cell--tree-node',
        'ant-checkbox-wrapper',
        'vxe-tree--btn-wrapper'
      ]
      let nowNodeClass = $event.target.classList || []
      console.log({ nodeClass })

      // 如果点击的是复选框、树icon，则不做任何处理
      // if (nodeClass.includes(nowNodeClass[0])) return

      // 复选框区域盒子
      let className = $event.target.className
      // if (className.includes('checkbox') || className.includes('col_2')) return
      console.log('点击单元格', $event, row.id)
      /*       this.$nextTick(() => {
              Array.from(document.getElementsByTagName('tbody')[0].childNodes).forEach(item => {
                if (item.attributes[0].value == row.id) {
                  item.className = item.className+' flxed-row'
                  console.log({ item }, 456)
                }
                if(item.attributes[0].value == this.oldRowId){
                  item.className = 'vxe-body--row'
                }
              })
              this.oldRowId=row.id
            }) */
      this.$refs.xTree?.toggleTreeExpand(row)

      // 获取全部展开的数据
      const treeExpandRecords = this.$refs.xTree?.getTreeExpandRecords()
      console.log({ treeExpandRecords })
      this.$bus.$emit(
        'getExpandRowKeys',
        treeExpandRecords.map(({ key }) => key)
      )
    },
    // 名称点击事件
    onTitleClick(row, e) {
      console.log(row)
      if (row.isPlatform && this.checkPermission()) {
        /* this.$router.push({ path: `/system/platform/detail/${row.id}?&name=${row.platformName}` }) */
        const newUrl = `/system/platform/detail/${row.id}?&name=${row.platformName}`
        const a = document.createElement('a')
        a.href = newUrl
        a.target = '_blank'
        a.click()
        document.removeChild(a)
      }
      if (row.isProduct) {
/*         console.log({ ctrl是否按下: e.ctrlKey })
        if (e.ctrlKey || e.metaKey) {
          let routeUrl = router.resolve({ path: `/production/manage/detail/${row.id}?&name=${row.productionName}` })
          window.open(routeUrl.href, '_blank')
        } else {
          this.$router.push({ path: `/production/manage/detail/${row.id}?&name=${row.productionName}` })
        } */
        const newUrl = `/production/manage/detail/${row.id}?&name=${row.productionName}`
        const a = document.createElement('a')
        a.href = newUrl
        a.target = '_blank'
        a.click()
        document.removeChild(a)
      }
    },
    // 删除操作
    onRemoveRows(e) {
      if (!Object.keys(treeCheckedRow).length) {
        this.$message.warning('请选择一条记录！')
        return
      }

      this.$confirm({
        title: '确认删除',
        content: '是否删除选中的作品数据?',
        onOk: () => {
          let productIds = []

          // 找到选中的作品
          let selectionRows = Object.values(treeCheckedRow)
          selectionRows.forEach(item => {
            if (item.isProduct) {
              productIds.push(item.id)
            }
          })

          // 发起删除作品的请求
          deleteProduct({ ids: productIds.join(',') })
            .then(res => {
              this.$message.success('删除成功！')
              this.refreshTable()
            })
            .catch(err => {
              this.$message.error('删除失败！')
            })
        }
      })
    }
  }
}
</script>

<style lang="less">
.option_row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

// 表头固定，动态表格高度
.j-vxe-table {
  .vxe-table--body-wrapper {
    width: 100%;
    display: block;
    overflow: auto;
    max-height: calc(100vh - 330px);
  }
}

.vxe-table--body .row--hover {
  background: var(--back-color) !important;
}

// 浮动错位
.vxe-table--fixed-wrapper .vxe-body--row:last-child {
  height: 58px;
}

.vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: 34px;
}

.j-vxe-table .vxe-table--border-line {
  border-right: unset;
  border-left: unset;
  border-top: unset;
}

.j-vxe-table .vxe-cell--checkbox .vxe-checkbox--icon:before {
  border-width: 1px;
}

// 手动修改表头行样式
.j-vxe-table .vxe-table--header-wrapper {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fafafa !important;
}

.j-vxe-table .vxe-table--header-wrapper .vxe-header--row .vxe-header--column:not(.col--ellipsis) {
  padding: 12px 0;
}

.j-vxe-table .vxe-table--header-wrapper .vxe-table--header .col--ellipsis {
  height: 46px !important;
}

.j-vxe-table-cell-class {
  height: 46px !important;
}

// 手动修改表头行字体样式
.j-vxe-table .vxe-cell--title {
  font-weight: normal !important;
}

// 手动调整“名称”列表头样式
.j-vxe-table .vxe-header--row .vxe-header--column:nth-child(1) .vxe-cell {
  padding-left: 50px;
}

// 树形结构左侧一级部门图标样式
.j-vxe-table .row--level-0 .vxe-cell--tree-node .vxe-tree--btn-wrapper {
  padding-left: 13px;
}

// 树形结构左侧二级平台图标样式
.j-vxe-table .row--level-1 .vxe-cell--tree-node .vxe-tree--btn-wrapper {
  padding-left: 10px;
}

// 树形结构内容一级部门盒子样式
.j-vxe-table .row--level-0 .vxe-cell--tree-node .vxe-tree-cell {
  padding-left: 40px;
}

// 树形结构内容二级平台盒子样式
.j-vxe-table .row--level-1 .vxe-cell--tree-node .vxe-tree-cell {
  padding-left: 37px;
}

.product-table {
  position: relative;
}

.tree-name {
  display: flex;
  align-items: center;
}

.tree-name .name {
  display: block;
  cursor: pointer;
}

.tree-name .name:hover {
  color: var(--theme-color);
}

.department-name .name {
  cursor: default;
}

.department-name .name:hover {
  color: inherit;
}

/* *[style*='overflow: scroll'] {
  overflow: auto !important;
} */
.tree-name .product-name {
  padding-left: 2px;
}

.tree-name .tree-checkbox {
  margin-right: 10px;
  margin-left: -14px;
}

.self-checkbox-all {
  position: absolute;
  top: 12px;
  left: 22px;
  z-index: 10;
}
</style>
