var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-table", style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "self-checkbox-all" },
        [
          _c("a-checkbox", {
            attrs: { checked: _vm.checkAll, indeterminate: _vm.indeterminate },
            on: { change: _vm.onCheckAllChange },
          }),
        ],
        1
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          !_vm.spinning
            ? _c(
                "vxe-table",
                {
                  ref: "xTree",
                  staticClass: "j-vxe-table",
                  staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
                  attrs: {
                    "keep-source": "",
                    "show-overflow": "",
                    "row-id": "key",
                    "cell-class-name": "j-vxe-table-cell-class",
                    "row-config": { isHover: true },
                    data: _vm.tableData,
                    "tree-config": _vm.treeConfig,
                  },
                  on: {
                    "toggle-tree-expand": _vm.onToggleTreeExpand,
                    "cell-click": _vm.onCellClick,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("a-empty", {
                              attrs: { image: _vm.simpleImage },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2799289094
                  ),
                },
                [
                  _c("vxe-column", {
                    attrs: {
                      title: "名称",
                      width: "340",
                      fixed: _vm.isDesktop() ? "left" : false,
                      "tree-node": "",
                      "show-header-overflow": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                {
                                  class: [
                                    "tree-name",
                                    { "department-name": row.isDepartment },
                                  ],
                                },
                                [
                                  row.isProduct
                                    ? _c("a-checkbox", {
                                        staticClass: "tree-checkbox",
                                        attrs: { checked: row.checked },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onRowCheckBoxChange(row)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        "name",
                                        { "product-name": row.isProduct },
                                      ],
                                      style: _vm.css,
                                      on: {
                                        click: (e) => _vm.onTitleClick(row, e),
                                      },
                                    },
                                    [
                                      row.isProduct &&
                                      row.sequenceNo != undefined &&
                                      row.sequenceNo !== "0"
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "min-width": "28px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    row.sequenceNo.length == 1
                                                      ? "00" + row.sequenceNo
                                                      : row.sequenceNo.length ==
                                                        2
                                                      ? "0" + row.sequenceNo
                                                      : row.sequenceNo
                                                  ) +
                                                  "-\n              "
                                              ),
                                            ]
                                          )
                                        : row.isProduct &&
                                          row.sequenceNo === "0"
                                        ? _c("span", {
                                            staticStyle: {
                                              width: "28px",
                                              display: "inline-block",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.showName) +
                                          _vm._s(row.adminUsers ? "-" : "") +
                                          "\n              "
                                      ),
                                      row.adminUsers
                                        ? _vm._l(
                                            row.adminUsers,
                                            function (item, index) {
                                              return _c(
                                                "span",
                                                { key: index },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      (item && item.userName) ||
                                                        ""
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        index !=
                                                          row.adminUsers
                                                            .length -
                                                            1
                                                          ? "|"
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2288352341
                    ),
                  }),
                  _c("vxe-column", {
                    attrs: { title: "数量", width: "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.isPlatform
                                ? _c(
                                    "a-tooltip",
                                    {
                                      staticClass: "table-tree-tool",
                                      attrs: {
                                        placement: "right",
                                        color: "#fff",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "span",
                                          { staticClass: "longin-tooltip" },
                                          [_vm._v("作品数量")]
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(row.productTotal || 0)),
                                      ]),
                                    ],
                                    2
                                  )
                                : row.isProduct
                                ? _c(
                                    "a-tooltip",
                                    {
                                      staticClass: "table-tree-tool",
                                      attrs: {
                                        placement: "right",
                                        color: "#fff",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "span",
                                          { staticClass: "longin-tooltip" },
                                          [_vm._v("章节数量")]
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(row.chapterCount || 0)),
                                      ]),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1302178909
                    ),
                  }),
                  _c("vxe-column", {
                    attrs: { title: "单位", width: "70" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.isPlatform
                                ? _c("span", [_vm._v("部")])
                                : _vm._e(),
                              row.isProduct
                                ? _c("span", [_vm._v("话")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2303414867
                    ),
                  }),
                  _c("vxe-column", {
                    attrs: { title: "未交稿预警", width: "220" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.taskStatus
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "3px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(row.taskStatus.chapterSort)
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          row.taskStatus.chapterSort && "|"
                                        ) +
                                        "\n            "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "3px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            row.taskStatus.currentStage &&
                                              row.taskStatus.currentStage
                                                .stageName
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          row.taskStatus.currentStage && "|"
                                        ) +
                                        "\n            "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "3px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            row.taskStatus.currentUser &&
                                              row.taskStatus.currentUser
                                                .userName
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          row.taskStatus.warningState && "|"
                                        ) +
                                        "\n            "
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(row.taskStatus.warningState)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3987362409
                    ),
                  }),
                  _c("vxe-column", {
                    attrs: { title: "拖稿数量" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.taskStatus
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            row.taskStatus.delayCount
                                              ? row.taskStatus.delayCount
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      117824870
                    ),
                  }),
                  _c("vxe-column", {
                    attrs: { minWidth: "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.adminUsers && row.productTotal > 50
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("a-pagination", {
                                        attrs: {
                                          size: "small",
                                          showLessItems: true,
                                          defaultPageSize: 50,
                                          "default-current": 1,
                                          total: row.productTotal - 0,
                                        },
                                        on: {
                                          change: (page, pageSize) => {
                                            _vm.treeTablePageChange(
                                              page,
                                              pageSize,
                                              row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1810938981
                    ),
                  }),
                ],
                1
              )
            : _c("div", { staticStyle: { width: "100%", height: "500px" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "0", opacity: "0", overflow: "hidden" } },
        [
          _c("a-table", {
            ref: "table",
            attrs: {
              id: "out-table",
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              pagination: false,
              dataSource: _vm.outTabelData,
              scroll: { x: "max-content", y: "calc(100vh - 323px)" },
            },
            scopedSlots: _vm._u([
              {
                key: "adminUsers",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (record.adminUser && record.adminUser.userName) || ""
                        ) +
                        "\n      "
                    ),
                  ])
                },
              },
              {
                key: "pagePattern",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          record.pagePattern == "PIECE"
                            ? "条漫"
                            : record.pagePattern == "PAGE"
                            ? "页漫"
                            : record.pagePattern == "PIECE_PAGE"
                            ? "条漫+页漫"
                            : ""
                        ) +
                        "\n      "
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }